/* eslint-disable react/prop-types */
import Tooltip from '@material-ui/core/Tooltip'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import useFolderDropTarget from 'components/AssetPanels/hooks/useFolderDropTarget'
import { TranslationContext } from 'contexts/TranslationContext'
import * as PT from '~/PropTypes'
import { refTimeToHHMMSSMSMS } from '~/Util'
import * as Actions from '~/actions'
import { InputRename } from '~/components/base/InputRename/InputRename'
import { SOURCE_FILE_TYPES, PLAYBACK_STATE } from '~/enums'
import { useAction } from '~/hooks'
import * as Assets from '~/models/Asset'
import { selectInOutPointsMoving, selectPreviewMode, selectorSplitPlayers } from '~/selectors/preview'
import { selectIsResizingEditor } from '~/selectors/useInterface'
import { PREVIEW_MODE } from '~/config/constants/preview'
import './BaseAssetsListItem.scss'
import useDraggable from './hooks/useDraggable'

const noop = () => {}

/**
 * @param {Assets.MediaAsset|Assets.ImageAsset|Assets.AudioAsset} asset
 */
function MediaInfo({ asset }) {
  const width = asset.width || asset.settings?.size?.width
  const height = asset.height || asset.settings?.size?.height
  const { t } = useContext(TranslationContext)

  return (
    <div className="media-info">
      <span className="media-info__name">{asset.name}</span>
      <ul className="media-info__list">
        <If condition={asset.taskInfo}>
          <li className="media-info__item">
            {t('MEDIA_INFO_LIST_TASK_STATUS')}
            :
            <span className="media-info__value">{`${asset.taskInfo.status} (${asset.taskInfo.progress}%)`}</span>
          </li>
        </If>
        <If condition={asset.filetype}>
          <li className="media-info__item">
            {t('MEDIA_INFO_LIST_ITEM_TYPE')}
            :
            <span className="media-info__value">{asset.filetype}</span>
          </li>
        </If>
        <If condition={asset.size}>
          <li className="media-info__item">
            {t('MEDIA_INFO_LIST_ITEM_SIZE')}
            :
            <span className="media-info__value">{`${(asset.size / (1024 * 1024)).toFixed(2)} MB`}</span>
          </li>
        </If>

        <If condition={width && height}>
          <li className="media-info__item">
            {t('MEDIA_INFO_LIST_ITEM_RESOLUTION')}
            :
            <span className="media-info__value">{`${width}x${height}`}</span>
          </li>
        </If>
        <If condition={!(asset instanceof Assets.ImageAsset)}>
          <li className="media-info__item">
            {t('MEDIA_INFO_LIST_ITEM_DURATION')}
            :
            <span className="media-info__value">{refTimeToHHMMSSMSMS(asset.duration)}</span>
          </li>
        </If>
        <If condition={asset instanceof Assets.VideoAsset && asset.fps}>
          <li className="media-info__item">
            {t('MEDIA_INFO_LIST_ITEM_FRAME_RATE')}
            :
            <span className="media-info__value">{`${(asset.fps).toFixed(3)}`}</span>
          </li>
        </If>
        <If condition={__CFG__.MEDIA_ITEM.CODECS_INFO
          && !(asset instanceof Assets.ImageAsset)
          && asset.codecsInfo}
        >
          <li className="media-info__item">
            {t('MEDIA_INFO_LIST_ITEM_CODEC')}
            :
            <span className="media-info__value">{asset.codecsInfo}</span>
          </li>
          <If condition={asset.audioInfo}>
            <li className="media-info__item">
              {t('MEDIA_INFO_LIST_ITEM_AUDIO')}
              :
              <span className="media-info__value">
                44100
                {' '}
                {t('MEDIA_INFO_LIST_ITEM_HZ_STEREO')}
              </span>
            </li>
          </If>
        </If>
      </ul>
    </div>
  )
}

// ---

const BaseAssetsListItem = React.forwardRef((props, contextMenuRef) => {
  const {
    className, children,
    asDragPreview,
    composeDragItem,
    selected,
    /** @type {Assets.AbstractAsset} */
    asset,
    isFolder,
    renaming,
    selectableRef = noop,
    isSelecting = false,
    isSelected: reactSelectableIsSelected = false,
    draggable = true,
    onClickContextMenu = noop,
    onItemMouseDown = noop,
    onItemClick = noop,
    selectItem = noop,
  } = props

  // TODO:
  //  add background image, if available?

  const inputRef = useRef()
  const playbackState = __CFG__.PREVIEW.AUTO_PLAY_SELECTED_MEDIA
    ? PLAYBACK_STATE.PLAY : PLAYBACK_STATE.PAUSE
  const setPlaybackClip = useAction(Actions.playback.setPlaybackClip, asset, playbackState)
  const setPreviewLoading = useAction(Actions.preview.setPreviewLoading)
  const switchDirectory = useAction(Actions.folders.openMediaDirectory, SOURCE_FILE_TYPES.MEDIA)
  const renameFolder = useAction(Actions.folders.renameFolder, SOURCE_FILE_TYPES.MEDIA, asset.id)
  const playingClip = useSelector(state => state.playback.playingAsset)
  const activePreviewMode = useSelector(selectPreviewMode)
  const isSplittedPreview = useSelector(selectorSplitPlayers)
  const isResizing = useSelector(selectIsResizingEditor)

  const isMovingIOPoints = useSelector(selectInOutPointsMoving)

  const [ , setDraggable ] = useDraggable(asset, {
    composeData: composeDragItem,
    draggable,
  })

  const onSelectItem = useCallback(
    () => {
      if (!isResizing
        && ((activePreviewMode !== PREVIEW_MODE.TIMELINE) || isSplittedPreview)) {
        if (!(asset instanceof Assets.AudioAsset)
          && !(asset instanceof Assets.ImageAsset)
          && !(asset instanceof Assets.TextAsset)
          && (playingClip?.id !== asset.id)) {
          setPreviewLoading(true)
        }
        setPlaybackClip()
      }
    },
    [
      setPlaybackClip,
      setPreviewLoading,
      asset,
      playingClip,
      activePreviewMode,
      isSplittedPreview,
      isResizing,
    ]
  )

  const handleClickContainer = e => {
    if (isMovingIOPoints) return
    const leftButtonClicked = e.which === 1 || e.button === 0
    if (e.ctrlKey || e.shiftKey || !e.currentTarget.contains(e.target)) return
    if (leftButtonClicked) {
      if (!isFolder) {
        onSelectItem()
        onItemClick(asset.id, () => selectItem(e))
      }
    }
  }

  const handleMouseDownContainer = e => {
    if (isMovingIOPoints) return
    if (e.ctrlKey || e.shiftKey || !e.currentTarget.contains(e.target)) return
    onItemMouseDown(asset.id, () => selectItem(e))
  }

  const handleClickContent = e => {
    if (isMovingIOPoints) return
    const leftButtonClicked = e.which === 1 || e.button === 0
    if (e.ctrlKey || e.shiftKey || !e.currentTarget.contains(e.target)) return
    if (leftButtonClicked) {
      if (isFolder) {
        switchDirectory(asset)
      }
    } else {
      onClickContextMenu(e)
    }
  }

  useEffect(() => {
    if (isFolder && asset.isNew) {
      setTimeout(() => {
        inputRef.current.click()
      })
    }
  }, [ asset.isNew, isFolder ])

  const isSelected = reactSelectableIsSelected || selected

  const [ collectedDropProps, drop ] = useFolderDropTarget(asset.id)

  return (
    <div
      ref={asDragPreview ? item => { selectableRef(item) } : item => {
        selectableRef(item)
        setDraggable(item)
      }}
      onMouseDown={!isSelecting && __CFG__.SOURCE_FILES_MANAGEMENT.SELECTABLE
        ? handleMouseDownContainer
        : noop}
      /**
       * onMouseUp because of MediaControls nodes get unmounted
       * on mouseDown. So click event is not triggering unlike mouseUp
      // Double call setPlaybackClip, delete if it does not cause errors in the future
       onMouseUp={!isSelecting && __CFG__.SOURCE_FILES_MANAGEMENT.SELECTABLE
         ? handleClickContainer
        : noop}
      */
      onClick={handleClickContainer}
      className={cx('source-asset', className, {
        'source-asset--selected': isSelected || isSelecting,
        'source-asset--dragging': asDragPreview,
      })}
    >
      <div
        // eslint-disable-next-line no-param-reassign
        ref={isFolder ? ref => { drop(ref); contextMenuRef.current = ref } : contextMenuRef}
        onContextMenu={e => { e.preventDefault() }}
        onMouseUp={!isSelecting && __CFG__.SOURCE_FILES_MANAGEMENT.SELECTABLE
          ? handleClickContent
          : noop}
        onClick={handleClickContent}
        className={cx('source-asset__content', {
          'source-asset__content--drop-target': __CFG__.SOURCE_FILES_MANAGEMENT.MOVE_TO_FOLDERS
          && collectedDropProps.isOver,
        })}
      >
        {children}
      </div>
      <If condition={!asDragPreview}>
        <Tooltip
          classes={{
            popper: 'tooltip media-tooltip',
          }}
          title={
            asset instanceof Assets.VideoAsset
            || asset instanceof Assets.AudioAsset
            || asset instanceof Assets.ImageAsset
              ? (<MediaInfo asset={asset} />)
              : ''
          }
        >
          <Choose>
            <When condition={renaming}>
              <div>
                <InputRename
                  ref={inputRef}
                  className="folder-name"
                  activeName={asset.name}
                  maxVisibleLetters={16.5}
                  letterWidth={11}
                  onRename={renameFolder}
                />
              </div>
            </When>
            <Otherwise>
              <div className="source-asset__name">
                {asset.name}
              </div>
            </Otherwise>
          </Choose>
        </Tooltip>
      </If>
    </div>
  )
})

BaseAssetsListItem.propTypes = {
  asset: PT.MediaItem.isRequired,
  selected: PropTypes.bool,
  asDragPreview: PropTypes.bool,
  composeDragItem: PropTypes.func,
  isFolder: PropTypes.bool,
  renaming: PropTypes.bool,
}

BaseAssetsListItem.defaultProps = {
  selected: false,
  asDragPreview: false,
  composeDragItem: () => {},
  isFolder: false,
  renaming: false,
}

// ---

export default BaseAssetsListItem
