import * as ActionTypes from 'actions/ActionTypes'
import * as ActionsLayer from 'actions/layer'
import { groupBy, isEmpty } from 'lodash'
import { PlaceholderAsset, TransitionAsset, VideoAsset } from 'models/Asset'
import Layer from '~/models/Layer'
import * as Selectors from '~/selectors'
import { parseLoadedFile } from '~/Util/sourceFile'
import { createAssetFromSourceFile } from './createAssetFromSourceFile'
import { insertLayer } from './insertLayer'
import { setReference } from './project'

import { patchDnDItemAssets } from '~/components/Timeline/DragAndDrop/lib/patchDnDAssets'
import { TRANSITIONS } from '~/enums'
import { addAttachedTransition } from '~/helpers/actions/addAttachedTransition'
import { addRelatedAssets } from '~/helpers/actions/addRelatedAssets'
import { closeEditingFormOfDeletion } from '~/helpers/actions/closeEditingFormOfDeletion'
import { getTransitionStartTime } from '~/helpers/actions/getTransitionStartTime'
import { normalizeAssetStartTime } from '~/helpers/actions/normalizeAssetStartTime'
import { splitAssetsByType } from '~/helpers/actions/splitAssetsByType'
import { isAvailableAttachTransition } from '~/helpers/assets/isAvailableAttachTransition'
import { time2Pixel } from '~/Util'
import { DROP_STATUS, getDropStatusPosition } from './dragActionHelpers/getDropStatusPosition'
import { getInitialMoveMultipleAssetsData } from './dragActionHelpers/getInitialMoveMultipleAssetsData'
import { addNewLayers } from './dragActionIntermediaries/addNewLayers'
import { changeLayers } from './dragActionIntermediaries/changeLayers'
import { updateAssetsPosition } from './dragActionIntermediaries/updateAssetsPosition'
import { updateLayerIndexes } from './dragActionHelpers/updateLayerIndexes'
import { openAssetSettingForm } from './mainView'
import { selectInOutPointsTimeLineAssetID } from '~/selectors/preview'

export const setIsSliderOnBufferingPos = payload => ({
  type: ActionTypes.SET_IS_SLIDER_ON_BUFFERING_POS,
  payload,
})

export const setTimelineAssetIds = payload => ({
  type: ActionTypes.SET_TIMELINE_ASSET_IDS,
  payload,
})

export const updateSlider = time => ({
  type: ActionTypes.UPDATE_SLIDER,
  payload: { time },
})

export const rewind = time => ({
  type: ActionTypes.TIMELINE_REWINDED,
  payload: { time },
})

export const clearTimeline = () => async dispatch => dispatch({
  type: ActionTypes.TIMELINE_CLEARED,
})

export const setScrollLeft = scrollLeft => ({
  type: ActionTypes.TIMELINE_SCROLL_LEFT,
  payload: { scrollLeft },
})

export const setIsMovingSlider = isMovingSlider => ({
  type: ActionTypes.SET_IS_MOVING_SLIDER,
  payload: { isMovingSlider },
})

/**
 * @param {number} index
 * @param {TimelineLayer[]} [layers]
 */
export const insertLayers = (
  index, layers, { insertBefore = true, addToHistory = true }
) => ({
  type: ActionTypes.INSERT_LAYERS,
  payload: { index, layers, insertBefore, addToHistory },
})

export const muteLayer = (id, mute) => ({
  type: ActionTypes.LAYER_MUTED,
  payload: { id, mute },
})

export const toggleVisibleLayer = layerId => ({
  type: ActionTypes.LAYER_TOGGLE_VISIBLE,
  payload: { layerId },
})

export const setScrollX = (sliderTime,
  scale, offset = 0) => setScrollLeft(time2Pixel(sliderTime, scale) - offset)


export const onScrollX = (newSliderTime, offset = 0) => async (dispatch, getState) => {
  const { scale, sliderTime } = getState().timeline
  dispatch(setScrollX(newSliderTime ?? sliderTime, scale, offset))
}

export const changeLayerPosition = (id, index) => (dispatch, getState) => {
  const prevIndex = Selectors.getLayers(getState()).findIndex(l => l.id === id)
  dispatch({
    type: ActionTypes.LAYER_CHANGE_POSITION,
    payload: { id, index, prevIndex },
  })
}


export const deleteTransitions = transitions => (dispatch, getState) => {
  closeEditingFormOfDeletion(dispatch, getState(), transitions)
  dispatch({ type: ActionTypes.DELETE_TRANSITIONS,
    payload: { transitions } })
}

export const deletePlaceholders = (placeholders, options) => dispatch => {
  dispatch({ type: ActionTypes.DELETE_PLACEHOLDERS, payload: { placeholders, options } })
}

/**
 * @param {Asset|Asset[]} assets
 */
export const deleteAssets = assets => async (dispatch, getState) => {
  const state = getState()
  const allAssets = Selectors.getAssets(state)
  const assetIds = assets.map(a => a.id)
  const assetsToRemove = allAssets.filter(a => assetIds.includes(a.id))
  const layers = Selectors.getLayers(state)
  const transitionsToRemove = [].concat(assetsToRemove)
    .filter(asset => asset instanceof TransitionAsset)
  let {
    placeholders,
    transitions,
    others,
  } = splitAssetsByType(allAssets, assetsToRemove, layers)
  if (isEmpty(transitionsToRemove)) {
    // NOTE: if an asset is deleted along with an attached transition
    transitions = addAttachedTransition(transitions, allAssets, others)
  }
  const relatedAssets = addRelatedAssets(!isEmpty(transitionsToRemove)
    ? transitionsToRemove : transitions, allAssets, others)
  // NOTE: order is important
  // NOTE: empty arrays is important (for undo-redo)
  await dispatch(deletePlaceholders(placeholders))
  await dispatch(deleteTransitions(transitions))

  closeEditingFormOfDeletion(dispatch, getState(), others)

  await dispatch({
    type: ActionTypes.DELETE_ASSETS,
    payload: {
      relatedAssets,
      assets: others,
      transitions,
    },
  })
}

export const rippleDelete = assets => async (dispatch, getState) => {
  const state = getState()
  const allAssets = Selectors.getAssets(state)
  const assetIds = assets?.map(a => a.id)
  const assetsToRemove = allAssets.filter(a => assets ? assetIds.includes(a.id) : a.selected)
  let {
    placeholders = [],
    transitions = [],
    others = [],
  } = groupBy(
    assetsToRemove.filter(a => !(a instanceof TransitionAsset)),
    asset => {
      if (asset instanceof PlaceholderAsset) {
        return 'placeholders'
      }
      return 'others'
    }
  )

  if (others.length || placeholders.length) {
    const actionAssets = others.concat(placeholders)
    const firstAsset = actionAssets.sort((a, b) => a.startTime - b.startTime)[0]
    const patchedAssets = state.layer.assets.filter(a => !actionAssets.some(aa => aa.id === a.id)
      && a.startTime > firstAsset.startTime)
    transitions = Selectors.getTransitionAssets(state)
      .filter(t => others.some(a => t.isAttachedTo(a.id)))

    await dispatch(ActionsLayer.clearAssetsSelection())
    await dispatch(deleteTransitions(transitions))

    closeEditingFormOfDeletion(dispatch, state, others)

    await dispatch({
      type: ActionTypes.RIPPLE_DELETE_ASSETS,
      payload: {
        assets: others,
        transitions,
        placeholders,
        patchedAssets,
      },
    })
  }
}

export const deleteLayer = (id, isHovering = false,
  isTransit = false, recordInHistory = true) => async (
  dispatch, getState
) => {
  const layers = Selectors.getLayers(getState())
  const layer = layers.find(l => l.id === id)
  const index = layers.findIndex(l => l.id === id)
  const layerAssets = Selectors.getLayerAssets(getState(), id)
  if (!isHovering || !layerAssets.length) {
    // NOTE: Order is imiportant for the history feature
    await dispatch({
      type: ActionTypes.LAYER_REMOVED,
      payload: { ids: [].concat(id), layer, index, isHovering, isTransit, recordInHistory },
    })
    if (layerAssets.length) {
      await dispatch(deleteAssets(layerAssets))
    }
  }
}

export const deleteLayers = ids => async (
  dispatch, getState) => {
  const layerAssets = Selectors.getLayerAssets(getState(), ids)
  if (layerAssets.length) {
    if (layerAssets.length) {
      await dispatch(deleteAssets(layerAssets))
    }
    await dispatch({
      type: ActionTypes.LAYER_REMOVED,
      payload: { ids },
    })
  }
}

/**
 *  For MediaRecording temporary logic
 */
export const deleteTempAssets = ids => ({
  type: ActionTypes.DELETE_TEMP_ASSETS,
  payload: ids,
})

/**
 * For MediaRecording temporary logic
 */
export const forceDeleteEmptyLayer = ids => ({
  type: ActionTypes.FORCE_DELETE_LAYER,
  payload: { ids },
})

export const deleteSelectedAssets = () => (dispatch, getState) => {
  const { assets } = getState().layer
  const selectedAssets = assets.reduce((result, el) => {
    if (el.selected) {
      result.push(el)
      /* const isDissolveTranzitionAsset = el.rightVideoAssetId && el.rightVideoAssetId
      if (isDissolveTranzitionAsset) {
        const rightAsset = assets.find(asset => asset.id === el.rightVideoAssetId)
        const leftAsset = assets.find(asset => asset.id === el.leftVideoAssetId)
        if (rightAsset) result.push(rightAsset)
        if (leftAsset) result.push(leftAsset)
      } */
    }

    return result
  }, [])
  if (selectedAssets.length > 0) {
    dispatch(deleteAssets(selectedAssets))
  }
}

export const setScale = scale => ({
  type: ActionTypes.SET_TIMELINE_SCALE,
  payload: { scale },
})

export const setDuration = duration => ({
  type: ActionTypes.SET_TIMELINE_DURATION,
  payload: { duration },
})

export const hoverAssetByLayerIndex = (hoveredLayerIndex, hoveringItem) => async dispatch => {
  // @link https://docs.google.com/document/d/1vsdg-XX2805oFctMGgXkvJPQAes0kQJLksRKMBejKHg/edit?ts=5e997f22#bookmark=id.12ypdqbtsajx
  // If dragged clip hovers an asset, and layer above is contais asset at future hovering position,
  // create new empty layer for dragged clip personally
  if (hoveredLayerIndex === 0 || hoveringItem.intersectedPxls !== 0) {
    await dispatch(insertLayer(hoveredLayerIndex, new Layer(), true))
  }
}

export const focusTimelineRegion = () => dispatch => {
  dispatch({
    type: ActionTypes.TIMELINE_RECEIVED_FOCUS,
  })
}

export const startDraggingItemOverTimeline = () => dispatch => {
  // Drag clip over timeline should be considered as focusing timeline block
  // @link http://18.184.210.86/issues/335
  dispatch(focusTimelineRegion())

  dispatch({
    type: ActionTypes.DRAG_OVER_TIMELINE_STARTED,
    payload: undefined,
  })
}

export const addAssetFromFileAtSliderPosition = file => (dispatch, getState) => {
  const state = getState()
  const { sliderTime, layers } = state.timeline

  const targetLayerIndex = 0
  let targetLayer = layers[targetLayerIndex]
  const rightSideAssetsByLayer = layers.map(layer => Selectors
    .getRightSideAssetsBySliderTimeAndLayerByEndTime(state, layer.id))
  const layerIndex = rightSideAssetsByLayer.findIndex(assets => assets.length === 0)
  targetLayer = layerIndex !== -1 ? layers[layerIndex] : null

  let assetLayerId
  if (targetLayer) {
    assetLayerId = targetLayer.id
  } else {
    assetLayerId = layers[layers.length - 1].id
  }
  const referenceVideo = Selectors.getReferenceVideoAsset(state)

  const asset = createAssetFromSourceFile(file, {
    layerId: assetLayerId,
    startTime: sliderTime,
    pregeneratedId: file.id,
    videoSize: { width: referenceVideo.width, height: referenceVideo.height },
  })

  dispatch(ActionsLayer.addTempAssetToTimeline(asset))
}

export const addAssetFromFile = (
  file = null,
  layerId,
  startTime,
  pregeneratedId,
  aspectRatio,
  ignoreSliderTimer = false,
  isTransit = false,
  deltaOnDrop,
  selected = true
) => (dispatch, getState) => {
  let pendingAsset = file
  const state = getState()
  const referenceVideo = Selectors.getReferenceVideoAsset(state)
  const creationOrder = Selectors.getLayerAssets(state, layerId).length
  const layers = Selectors.getLayers(state)

  if (file === null && pregeneratedId !== null) {
    pendingAsset = Selectors.getSourceFileById(state, pregeneratedId)
  }

  let assetLayer = layers.find(l => l.id === layerId)
  if (!assetLayer) {
    assetLayer = layers[layers.length - 1]
    const layerAssets = Selectors.getLayerAssets(getState(), assetLayer.id)
    if (layerAssets.length !== 0) {
      dispatch(insertLayer(layers.length))
      assetLayer = Selectors.getLayers(getState()).at(-1)
    }
  }

  const inOutPointsTimeLineAssetId = selectInOutPointsTimeLineAssetID(state)
  const inOutPointsTimeLineAsset = Selectors.selectAssetById(state, inOutPointsTimeLineAssetId)

  pendingAsset = file.id === inOutPointsTimeLineAsset?.fileId
    ? inOutPointsTimeLineAsset
    : file

  const asset = createAssetFromSourceFile(pendingAsset, {
    layerId: assetLayer.id,
    startTime: normalizeAssetStartTime(state, Selectors.getLayerAssets, {
      startTime,
      duration: pendingAsset.duration,
      layerId,
      ignoreStartOverlapping: ignoreSliderTimer,
    }),
    pregeneratedId,
    creationOrder,
    videoSize: { width: referenceVideo.width, height: referenceVideo.height },
    aspectRatio,
  })

  dispatch(ActionsLayer.addAsset(asset, ignoreSliderTimer, isTransit, deltaOnDrop, selected))

  return asset.id
}

export const dropSourceFile = (id,
  { layerId: baseLayerId, layerIndex,
    startTime, pregeneratedId, isTransit = false, deltaOnDrop }) => (dispatch, getState) => {
  const state = getState()
  const file = Selectors.getSourceFileById(state, id)
  const layers = Selectors.getLayers(state)
  const layerId = baseLayerId || layers[layerIndex].id

  if (Selectors.getAssets(getState()).length === 0 && file instanceof VideoAsset) {
    const { width, height, fpsNum, fpsDenum } = file
    dispatch(setReference({ width, height, fpsNum, fpsDenum, id: file.fileId }))
  }

  dispatch(
    addAssetFromFile(file, layerId, startTime, pregeneratedId, undefined,
      false, isTransit, deltaOnDrop)
  )
}

/**
 *
 * @param {ProjectData.ProjectAsset} asset
 * @param {{layerId: string, startTime: number, pregeneratedId: string}} param1
 */
export const loadProjectAsset = (projectAsset,
  { layerId, startTime, pregeneratedId, selected }) => dispatch => {
  const file = parseLoadedFile(projectAsset.sourceFileType, projectAsset)
  const { aspectRatio } = projectAsset?.settings || {}
  dispatch(addAssetFromFile(
    file,
    layerId,
    startTime,
    pregeneratedId,
    aspectRatio,
    true,
    undefined,
    undefined,
    selected
  ))
}

export const moveAsset = (
  assetId, { startTime, layerId, layerIndex, isTransit = false, deltaOnDrop }
) => async (dispatch, getState) => {
  let transitions = []
  const state = getState()
  const layers = Selectors.getLayers(state)
  const asset = Selectors.selectAssetById(state, assetId)
  const targetLayerId = layerId || layers[layerIndex]?.id
  // NOTE: order of dispatches is important for undo-redo handling
  // if video asset is moved on timeline, remove related transitions except fade in/out
  if (asset.canHaveTransition) {
    const [ , transitionsToRemove ] = Selectors.getTransitionsOfMovedAsset(state.layer, asset.id)
    transitions = transitionsToRemove
    // NOTE: for undo-redo also important sending empty array of transitionsToRemove
    await dispatch(deleteTransitions(transitionsToRemove))
  }

  await dispatch({
    type: ActionTypes.ASSET_MOVED_ON_TIMELINE,
    payload: {
      asset,
      layerId: targetLayerId,
      startTime: normalizeAssetStartTime(getState(), Selectors.getLayerAssets, {
        assetId,
        startTime,
        duration: asset.duration,
        layerId: targetLayerId,
      }),
      isTransit,
      deltaOnDrop,
      transitions,
    },
  })
  // await dispatch(ActionsLayer.shiftItemsByAsset(asset))
}

export const moveMultipleAssets = (
  assetId, {
    startTime,
    layerId,
    layerIndex,
    isTransit = false,
    deltaOnDrop,
    dragItem,
    isNewLayerAction,
    dropOffsetX,
    draggableBlockStartTime,
    isIntersectionPlaceholder,
    intersectionPlaceholder,
  }
) => async (dispatch, getState) => {
  const state = getState()
  const { dndPatchAssets } = state.layer
  // If store was patched after being (callback begin() from useDragSource) dnd
  const patchedDragItem = patchDnDItemAssets(dragItem, dndPatchAssets)

  const {
    currentDragItem,
    dragAssetIds,
    dragAssetsByLayerIndex,
    targetLayerId,
    countDragAssetsLayers,
    currentDragItemLayerId,
    currentDragItemLayerIndex,
    targetLayerIndex,
    staticAssets,
    layers,
    layerIds,
    firstdragAssetStartTime,
  } = getInitialMoveMultipleAssetsData({
    state,
    assetId,
    dragItem: patchedDragItem,
    layerId,
    layerIndex,
  })

  const serviceData = {
    assets: state.layer.assets.map(asset => (
      {
        id: asset.id,
        layerId: asset.layerId,
        startTime: asset.startTime,
      }
    )),
    insertBetweenLayerID: intersectionPlaceholder?.layerId,
    selectedDragIds: patchedDragItem.selectedDragIds,
    layerIds: Array.from(new Set(layerIds)),
  }

  const redoData = {
    assetId,
    startTime,
    layerId,
    layerIndex,
    isTransit,
    deltaOnDrop,
    dragItem: patchedDragItem,
    isNewLayerAction,
    dropOffsetX,
    draggableBlockStartTime,
    isIntersectionPlaceholder,
  }

  const dropStatus = getDropStatusPosition({
    currentDragItemLayerId,
    currentDragItemLayerIndex,
    targetLayerId,
    targetLayerIndex,
    isNewLayerAction,
  })

  const normalizeTime = startTime
  const shiftTime = (startTime - currentDragItem.startTime) + dropOffsetX

  let insertedLayersByStartIndex = {}
  let newDragAssetsByLayerIndex = null
  let isAllowedDropAssets = true
  let layersShiftTime = {}

  switch (dropStatus) {
    case DROP_STATUS.CREATE_NEW_LAYER: {
      const data = addNewLayers({
        countDragAssetsLayers,
        insertLayers,
        dispatch,
        layerIndex,
        dragAssetsByLayerIndex,
        getState,
      })
      newDragAssetsByLayerIndex = data.dragAssetsWithNewLayerId
      insertedLayersByStartIndex = data.insertedLayersByStartIndex
    }
      break
    case DROP_STATUS.UPDATE_ASSETS_POSITION_OF_CURRENT_LAYER:
    case DROP_STATUS.CHANGE_LAYER: {
      if (dropStatus === DROP_STATUS.CHANGE_LAYER) {
        const data = changeLayers({
          layers,
          targetLayerIndex,
          currentDragItemLayerIndex,
          dragAssetsByLayerIndex,
          staticAssets,
          insertLayers,
          dispatch,
          Layer,
          shiftTime,
          isIntersectionPlaceholder,
        })
        insertedLayersByStartIndex = data.newLayersByStartIndex
        newDragAssetsByLayerIndex = data.dragAssetsWithNewLayerId
      }

      // get all layers (if new ones have been added)
      const currentLayers = Selectors.getLayers(getState())
      // "updateLayerIndexes" - update layer indexes if a group has been moved up or down
      const draggableAssetsByUpdatedLayerIndex = updateLayerIndexes(
        newDragAssetsByLayerIndex ?? dragAssetsByLayerIndex, currentLayers
      )

      const data = updateAssetsPosition({
        layers,
        dragAssetsByLayerIndex: draggableAssetsByUpdatedLayerIndex,
        staticAssets,
        shiftTime,
        intersectionPlaceholder,
        deltaOnDrop,
      })
      // ShiftTime for static assets after drop draggable assets
      layersShiftTime = data.layersShiftTime
    }
      break
    default:
      isAllowedDropAssets = false
      break
  }

  if (isAllowedDropAssets) {
    await dispatch({
      type: ActionTypes.MULTIPLE_ASSETS_MOVED_ON_TIMELINE,
      payload: {
        dragAssetIds,
        isTransit: false,
        transitionsToMove: patchedDragItem?.dragTransitionIds,
        layerId: targetLayerId,
        shiftTime,
        startTime: normalizeTime,
        startTimeGroup: firstdragAssetStartTime + shiftTime,
        deltaOnDrop,
        dragAssetsByLayerIndex: newDragAssetsByLayerIndex ?? dragAssetsByLayerIndex,
        insertedLayersByStartIndex,
        layersShiftTime,
        layerIds,
        serviceData,
        redoData,
      },
    })
  }
}

export const undoMoveMultipleAssets = ({ serviceData, deltaOnDrop, startTime }) => dispatch => {
  dispatch({
    type: ActionTypes.UNDO_MULTIPLE_MOVE_ASSETS,
    payload: { serviceData, deltaOnDrop, startTime },
  })
}

// ---
// Attaching to another asset, isn't new transition
export const attachTransitionToAsset = (transitionId, leftAssetId, rightAssetId) => (
  (dispatch, getState) => {
    const transition = Selectors.selectAssetById(getState(), transitionId)
    dispatch({
      type: ActionTypes.TRANSITION_ATTACHED_TO_ASSET,
      payload: { transitionId, leftAssetId, rightAssetId, transition },
    })
  }
)

export const addTransitionFromFile = (
  file,
  transitionType,
  leftAssetId,
  rightAssetId,
  options
) => (dispatch, getState) => {
  const { pregeneratedId, layerId } = options
  const state = getState()
  const creationOrder = Selectors.getLayerAssets(state, layerId).length
  let rightAsset = state.layer.assets.find(asset => asset.id === rightAssetId)
  let leftAsset = state.layer.assets.find(asset => asset.id === leftAssetId)

  let additionAllowed = false

  switch (transitionType) {
    case TRANSITIONS.DISSOLVE:
      additionAllowed = isAvailableAttachTransition(leftAsset)
        && isAvailableAttachTransition(rightAsset)
      break
    case TRANSITIONS.FADEIN:
      additionAllowed = isAvailableAttachTransition(rightAsset)
      break
    case TRANSITIONS.FADEOUT:
      additionAllowed = isAvailableAttachTransition(leftAsset)
      break
    default:
      break
  }
  if (additionAllowed) {
    const transitionAsset = file.clone({
      leftVideoAssetId: leftAssetId,
      rightVideoAssetId: rightAssetId,
      type: transitionType,
      creationOrder,
    }, { pregeneratedId })

    dispatch(ActionsLayer.addAsset(transitionAsset))
    dispatch({
      type: ActionTypes.TRANSITION_ATTACHED_TO_ASSET,
      payload: {
        transitionId: transitionAsset.id,
        leftAssetId,
        rightAssetId,
      },
    })
    const newState = getState()
    rightAsset = newState.layer.assets.find(asset => asset.id === rightAssetId)
    leftAsset = newState.layer.assets.find(asset => asset.id === leftAssetId)
    const transitionStartTime = getTransitionStartTime(transitionAsset, leftAsset, rightAsset)

    dispatch({
      type: ActionTypes.TRANSITION_SET_START_TIME,
      payload: {
        startTime: transitionStartTime,
        transitionId: transitionAsset.id,
      },
    })
    dispatch(rewind(transitionStartTime))
  }
}

export const reattachTransition = (transitionId,
  leftAssetId, rightAssetId, oldLeftAssetId, oldRightAssetId) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.TRANSITION_REATTATCH,
    payload: {
      transitionId,
      leftAssetId,
      rightAssetId,
      oldLeftAssetId,
      oldRightAssetId,
    },
  })
  const newState = getState()
  const movedTransition = newState.layer.assets.find(asset => asset.id === transitionId)
  if (movedTransition) {
    dispatch(rewind(movedTransition.startTime))
  }
}

export const createNewTransitionForAsset = ({ id, transitionType },
  leftAssetId, rightAssetId, options = {}) => (dispatch, getState) => {
  const file = Selectors.getSourceFileById(getState(), id)
  dispatch(addTransitionFromFile(file, transitionType, leftAssetId, rightAssetId, options))
}

export const loadProjectTransitionAsset = (projectTransitionAsset,
  leftAssetId, rightAssetId, options = {}) => dispatch => {
  const file = parseLoadedFile(projectTransitionAsset.sourceFileType, projectTransitionAsset)
  dispatch(addTransitionFromFile(
    file,
    projectTransitionAsset.type,
    leftAssetId,
    rightAssetId,
    options
  ))
}

export const startDragAsset = assetId => ({
  type: ActionTypes.TIMELINE_DRAG_ASSET,
  payload: assetId,
})

export const endDragAsset = () => ({
  type: ActionTypes.TIMELINE_DRAG_END_ASSET,
})

export const startDragLayer = layerId => ({
  type: ActionTypes.TIMELINE_DRAG_LAYER,
  payload: layerId,
})

export const deleteTransitionAction = (
  transitions,
  relatedAssets,
  assets = [],
  history = false
) => ({ type: ActionTypes.DELETE_TRANSITIONS,
  payload: {
    transitions,
    relatedAssets,
    assets,
    history,
  } })

export const setDnDPatchAssets = assetIds => ({ type: ActionTypes.SET_DND_PATCH_ASSETS,
  payload: { assetIds } })

export const resetDnDPatchAssets = () => ({ type: ActionTypes.RESET_DND_PATCH_ASSETS })

export const endDragLayer = () => ({
  type: ActionTypes.TIMELINE_DRAG_END_LAYER,
})

export const onChangeSliderTime = time => dispatch => {
  dispatch({
    type: ActionTypes.TIMELINE_SET_SLIDER_TIME,
    payload: {
      time,
    },
  })
}


export const clearCopiedAssets = () => ({
  type: ActionTypes.CLEAR_COPIED_ASSETS,
  payload: {},
})


export const showSettings = () => (dispatch, getState) => {
  const selectedAssets = Selectors.getSelectedAssets(getState())

  if (selectedAssets.length === 1) {
    dispatch(openAssetSettingForm(selectedAssets[0].id))
  }
}

export const undoSplittingAssets = () => ({
  type: ActionTypes.UNDO_SPLITTING_ASSETS,
  payload: {},
})
