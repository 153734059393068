import React, { FC } from 'react'
import { MovableModalContainer } from 'components/MovableModal'
import { useSelector } from 'react-redux'
import { selectShowSaveSettings } from 'selectors/mainView'
import SaveSettings from './SaveSettings'

export const SaveSettingsContainer: FC = () => {
  const isShowSaveSettings = useSelector(selectShowSaveSettings)
  return (
    <MovableModalContainer
      visible={isShowSaveSettings}
      restorePosition={false}
      width={713}
      height={320}
      withBackground
    >
      <SaveSettings />
    </MovableModalContainer>
  )
}
