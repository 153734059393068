import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectSourcePlayerProgress } from 'selectors/preview'
import { useDiff, useConst, useBind } from '~/hooks'

/**
 * @typedef {object} ReactPlayerState
 * @property {boolean} isLoading
 * @property {boolean} isPlaying
 * @property {boolean} isReady
 */

const DEFAULT_STATE = {
  isReady: false,
  isPlaying: false,
  isLoading: false,
  isBuffering: false,
  progress: null,
  paused: null,
  duration: null,
  currentTime: null,
}

/**
 * @param player
 * @return {{isLoading: boolean, isPlaying: boolean, isReady: boolean}}
 */
export function getPlayerState(player) {
  // it can be both null and undefined, so non-strict comparison here
  const internalPlayer = player?.getInternalPlayer()
  if (!internalPlayer) {
    return DEFAULT_STATE
  }

  const { isReady, isPlaying, isLoading, buffering } = player
  const { paused, duration, currentTime } = internalPlayer
  const { isBuffering, progress } = buffering
  return { isReady,
    isPlaying,
    isLoading,
    isBuffering,
    progress,
    paused,
    duration,
    currentTime }
}

export default function usePlayer(params = {}) {
  const { isSourceMediaPlayer } = params
  const [ player, setPlayer ] = React.useState(null)
  const { isReady } = getPlayerState(player)
  const isInitializing = useDiff(isReady, (next, prev) => next === true && prev === false)
  const memoizedSourcePlayerProgress = useSelector(selectSourcePlayerProgress)

  // ---

  function usePlayerCallback(fn) {
    return React.useCallback(
      (...args) => {
        if (isReady) {
          fn(player, ...args)
        }
      },
      // Somehow lint thinks `isReady` is unnecessary dependency here. I have no idea why, but it's not.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [ fn, isReady, player ]
    )
  }

  // ---

  const onReadyPlayer = useCallback(player => {
    // init preview player with saved progress
    if (isSourceMediaPlayer) {
      player.seekTo(memoizedSourcePlayerProgress)
    }
    setPlayer(player)
  }, [ setPlayer, memoizedSourcePlayerProgress, isSourceMediaPlayer ])

  return [
    onReadyPlayer,

    {
      getState: useBind(getPlayerState, player),

      usePlayerEffect(fn, deps = []) {
        React.useEffect(
          () => {
            if (isReady) {
              fn(player, isInitializing)
            }
          },
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [ isReady, isInitializing, ...deps ]
        )
      },

      usePlayerCallback,

      // eslint-disable-next-line no-shadow
      seek: usePlayerCallback(useConst((player, time) => {
        player.seekTo(time)
      })),
    },
  ]
}
