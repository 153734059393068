import { PLAYER_TYPE, REGULATION_CONTROL, SOURCE_FILE_TYPES } from 'enums'

export type RegularControlActionType = typeof REGULATION_CONTROL[keyof typeof REGULATION_CONTROL]
export type PlayerType = typeof PLAYER_TYPE[keyof typeof PLAYER_TYPE]
export type SourceFileTypes = typeof SOURCE_FILE_TYPES[keyof typeof SOURCE_FILE_TYPES]

export enum SPLIT_RESIZER {
  MAIN = 'MAIN-RESIZER',
  TIMELINE = 'TIMELINE-RESIZER',
  PREVIEW = 'PREVIEW-RESIZER'
}
