import ProjectAsset from '~/models/ProjectData/ProjectAsset'

/**
 * @memberOf ProjectData
 * @extends ProjectData.ProjectAsset
 *
 */

class ProjectImageAsset extends ProjectAsset {

  // eslint-disable-next-line no-unused-vars
  constructor(jsonData, version) {
    super(jsonData)

    const { settings, mediaFileDuration, originDuration } = jsonData

    this._originDuration = originDuration || mediaFileDuration
    this._mediaFileDuration = mediaFileDuration
    this._settings = { ...settings }
  }

  get mediaFileDuration() {
    return this._mediaFileDuration
  }

  /**
   * @param {number} mediaFileDuration
   */
  set mediaFileDuration(mediaFileDuration) {
    this._mediaFileDuration = mediaFileDuration
  }

  /**
   * @returns {Object}
   */
  get settings() {
    return this._settings
  }

}

export default ProjectImageAsset
