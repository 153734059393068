import { ERROR_TYPE } from './ErrorType'
import { MediaUsedOnDeletionError } from './MediaUsedOnDeletionError'
import { NotFoundError } from './NotFoundError'

export const handleError = response => {
  if (response?.data?.error) {
    const { error } = response.data
    switch (error.type) {
      case ERROR_TYPE.USED_IN_PROJECTS: {
        const { title, message } = error
        throw new MediaUsedOnDeletionError({ title, message, type: error.type })
      }
      case ERROR_TYPE.FOLDER_NOT_EMPTY: {
        const { title, message } = error
        throw new MediaUsedOnDeletionError({ title, message, type: error.type })
      }
      case ERROR_TYPE.NOT_FOUND: {
        const { message } = error
        throw new NotFoundError(message)
      }
      default: {
        console.error('Unknown error handled:', error)
      }
    }
  }

  if (response?.data?.status?.errors?.length) {
    const commonErrorString = response.data.status.errors.map(error => JSON.stringify(error))
    throw new Error(commonErrorString)
  }
  return response
}
