import { isArray, isEmpty, isEqual, isNil } from 'lodash'
import { deleteFolder, moveItemsToFolder } from 'services/FoldersApiService'
import { fetchImagesFiles } from 'services/ImagesService'
import * as API from '~/ServerAPI'
import * as ActionTypes from '~/actions/ActionTypes'
import { checkStatusLongPolling } from '~/actions/project/checkStatusLongPolling'
import {
  FILE_STATUS,
  MEDIA_FILE_FILTERS,
  SOURCE_FILE_TYPES,
  MEDIA_ERROR_TYPE,
  NW_JS_APP_PROFILES,
  PLAYBACK_STATE
} from '~/enums'
import { MediaUsedOnDeletionError } from '~/errors/MediaUsedOnDeletionError'
import * as Selectors from '~/selectors'
import { fetchAudioFiles } from '~/services/AudioLibraryService'
import { addSourceFileAtSliderPosition } from './addSourceFileAtSliderPosition'
import { setPlaybackClip } from './playback'

export const CHECK_STATUS_INTERVAL = NW_JS_APP_PROFILES.includes(__APP_PROFILE__) ? 100 : 1000

const playbackState = __CFG__.PREVIEW.AUTO_PLAY_SELECTED_MEDIA
  ? PLAYBACK_STATE.PLAY : PLAYBACK_STATE.PAUSE

/**
 * @param {string} type
 * @param {object} [params]
 * @returns {Promise<API.AxiosResponse<API.FilesResourceResponse>>}
 */
function fetchFiles(type, params) {
  switch (type) {
    case SOURCE_FILE_TYPES.MEDIA:
      return API.getMedia(params)
    case SOURCE_FILE_TYPES.AUDIO:
      return fetchAudioFiles(params)
    case SOURCE_FILE_TYPES.TEXT:
      return API.fetchTextFiles(params)
    case SOURCE_FILE_TYPES.TRANSITIONS:
      return API.fetchTransition(params)
    case SOURCE_FILE_TYPES.IMAGES:
      return fetchImagesFiles(params)
    // TODO: filters/design
    default:
      throw new Error(`Unable to fetch unknown files type: ${type}`)
  }
}

// ---

const getDefaultLoadOptions = sourceFilesGroup => ({
  start: 0,
  count: __CFG__.SOURCE_FILES_MANAGEMENT.LOADING_NUMBER,
  ...sourceFilesGroup.params,
})

const uploadSourceFiles = async (state, getState, filesType, options, dispatch) => {
  const sourceFilesGroup = Selectors.getSourceFilesGroup(state, filesType)
  const params = { ...getDefaultLoadOptions(sourceFilesGroup), ...options }

  if (isEqual(sourceFilesGroup.params, params) && !options.force) {
    return
  }

  // eslint-disable-next-line no-param-reassign
  delete params.force

  dispatch({
    type: ActionTypes.SOURCE_FILES_REQUESTED,
    payload: { type: filesType, params },
  })

  const { start, filter } = params

  try {
    const response = await fetchFiles(filesType, {
      ...params,
      filter: filter !== MEDIA_FILE_FILTERS.All ? filter : undefined,
    })
    const items = response.data.entries
    if (filesType === SOURCE_FILE_TYPES.MEDIA) {
      items.filter(item => item.status && item.status !== FILE_STATUS.READY
          && item.status !== FILE_STATUS.UPLOAD).forEach(item => setTimeout(
        checkStatus, CHECK_STATUS_INTERVAL, item.id, dispatch, getState
      ))
      items.filter(item => item.status === FILE_STATUS.RENDER && item.taskId)
        .forEach(item => setTimeout(checkStatusLongPolling, 0, dispatch, item.taskId, item.id))
    }
    dispatch({
      type: ActionTypes.SOURCE_FILES_LOADED,
      payload: {
        type: filesType,
        start,
        items,
        totalCount: response.data.total_count,
      },
    })
  } catch (error) {
    console.error(`Failed to fetch source files type ${filesType}\n`, error)

    error.type = filesType
    dispatch({
      type: ActionTypes.SOURCE_FILES_LOADED,
      payload: error,
      error: true,
    })
  }
}

export const loadSourceFiles = (
  filesType,
  options = {}
) => async (dispatch, getState) => {
  const state = getState()
  if (isArray(filesType)) {
    const promises = filesType.map(async type => {
      await uploadSourceFiles(state, getState, type, options, dispatch)
    })
    await Promise.all(promises)
  } else {
    await uploadSourceFiles(state, getState, filesType, options, dispatch)
  }
}

export const requestDeleteFileOrFolder = async cb => {
  try {
    await cb()
  } catch (e) {
    if (e instanceof MediaUsedOnDeletionError) {
      throw e
    } else {
      console.error(e)
    }
  }
}

export const requestDeleteFile = (id, type, force = false) => async dispatch => {
  await requestDeleteFileOrFolder(async () => {
    if (isArray(id)) {
      const ids = await Promise.all(
        id.map(async itemId => {
          await API.deleteMediaFile(itemId, force)
          return itemId
        })
      )
      if (!isEmpty(ids) && !isNil(ids)) {
        ids.map(id => (
          dispatch({
            type: ActionTypes.SOURCE_FILE_DELETED,
            payload: { id, type },
          })
        ))
      }
    } else {
      await API.deleteMediaFile(id, force)
      dispatch({
        type: ActionTypes.SOURCE_FILE_DELETED,
        payload: { id, type },
      })
    }
  })
}

export const requestDeleteFolder = (id, type, force = false) => async dispatch => {
  await requestDeleteFileOrFolder(async () => {
    await deleteFolder(id, force)
    dispatch({
      type: ActionTypes.SOURCE_FILE_DELETED,
      payload: { id, type },
    })
  })
}

export const requestDeleteMedia = (items, type, force = false) => async dispatch => {
  for (const item of items) {
    if (item.type === 'folder') {
      dispatch(requestDeleteFolder(item.id, type, force))
    } else {
      dispatch(requestDeleteFile(item.id, type, force))
    }
  }
}

export const updateSourceFile = file => ({
  type: ActionTypes.SOURCE_FILE_UPDATED,
  payload: { file },
})

export const setSourceFilesParams = (type, params = {}) => ({
  type: ActionTypes.SOURCE_FILES_SET_PARAMS,
  payload: { type, params },
})

export const setSourceFilesParamsNoReload = (type, params = {}) => ({
  type: ActionTypes.SOURCE_FILES_SET_PARAMS_NO_RELOAD,
  payload: { type, params },
})

export const setSourceFilesUiParams = (type, uiParams = {}) => ({
  type: ActionTypes.SOURCE_FILES_SET_UI_PARAMS,
  payload: { type, uiParams },
})

export const updateSourceFileStatus = data => ({
  type: ActionTypes.SOURCE_FILE_STATUS_UPDATED,
  payload: { data },
})

const addFileToTimeline = async (id, dispatch) => {
  await dispatch(addSourceFileAtSliderPosition(id))
  const event = new CustomEvent('solveig-onVideoPrepared')
  document.dispatchEvent(event)
}

export async function checkStatus({ id, type }, dispatch, getState) {
  try {
    const response = await API.getMediaFileStatus(id)
    const { progress, status, error, taskInfo } = response.data
    if (error !== null) {
      dispatch(updateSourceFileStatus({ id, error }))
      return
    }
    if (status === FILE_STATUS.PREPARE_THUMBNAILS || status === FILE_STATUS.READY) {
      const sourceFile = Selectors.getSourceFileById(getState(), id)
      if (sourceFile.status !== FILE_STATUS.PREPARE_THUMBNAILS) {
        // do this only once for the file when it's ready to playback.
        const file = await API.getMediaFile(id, type)
        // update  item with new data, which contains thumbnail and media info
        const { data } = file
        await dispatch(updateSourceFile(data))
        await dispatch(setPlaybackClip(data, playbackState))

        if (__APP_PROFILE__ === 'package') {
          await addFileToTimeline(sourceFile.id, dispatch)
        }
      }
    }
    dispatch(updateSourceFileStatus({ id, progress, status, taskInfo }))
    if (status === FILE_STATUS.READY) {
      return
    }
    setTimeout(checkStatus, CHECK_STATUS_INTERVAL, { id, type }, dispatch, getState)
  } catch (e) {
    // in case of network issue, MediaFileStatus request will
    // fail and uploading item display Network Error.
    const error = {
      message: e.message,
      type: MEDIA_ERROR_TYPE.NETWORK_ERROR,
    }
    dispatch(updateSourceFileStatus({ id, error }))
  }
}

export const createMediaFile = (fileId, type) => async (dispatch, getState) => {
  try {
    const response = await API.getMediaFile(fileId, type)
    const item = response.data
    await dispatch({
      type: ActionTypes.SOURCE_FILE_CREATED,
      payload: { item },
    })
    const { status } = item
    if (status === FILE_STATUS.READY || status === FILE_STATUS.PREPARE_THUMBNAILS) {
      dispatch(setPlaybackClip(item, playbackState))
      if (__APP_PROFILE__ === 'package') {
        await addFileToTimeline(fileId, dispatch)
      }
    } else {
      const checkInfo = {
        id: item.id,
        type,
      }
      setTimeout(checkStatus, CHECK_STATUS_INTERVAL, checkInfo, dispatch, getState)
    }
  } catch (e) {
    console.error(`error createMediaFile fileId:${fileId} ${e}`)
  }
}

export const updatePreviewVideoSize = ({ width, height }) => ({
  type: ActionTypes.CHANGED_PREVIEW_VIDEO_SIZE,
  payload: { width, height },
})

export const moveMediaItemsToFolder = ({ folders, files,
  targetFolder,
  type }) => async dispatch => {
  try {
    if (targetFolder) {
      await moveItemsToFolder(folders, files, targetFolder)
    } else {
      await moveItemsToFolder(folders, files)
    }

    dispatch({ type: ActionTypes.SOURCE_FILES_MOVED,
      payload: { type, ids: [ ...folders, ...files ] } })
  // eslint-disable-next-line no-empty
  } catch (e) {

  }
}
