import { MEDIA_TYPE } from '~/enums'

const incompatibleFileExtensionsMap = [
  [ MEDIA_TYPE.MP4, MEDIA_TYPE.MXF ],
  [ MEDIA_TYPE.MOV, MEDIA_TYPE.MXF ],
  [ MEDIA_TYPE.HLS, MEDIA_TYPE.MXF ],
  [ MEDIA_TYPE.TS, MEDIA_TYPE.MXF ],
]

export const getIncompatibleFileExtensions = fileExtension => {
  if (__CFG__.ALLOW_INCOMPATIBLE_FILES) {
    return []
  }
  return incompatibleFileExtensionsMap.reduce(
    (acc, group) => [
      ...acc,
      ...(group.includes(fileExtension)
        ? group.filter(ext => ext !== fileExtension) : []),
    ],
    []
  )
}

export const getConflictedFileExtensions = (incompatibleFileExtensions, extensions) => (
  extensions.filter(ext => incompatibleFileExtensions.includes(ext))
)
