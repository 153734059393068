import React from 'react'
import { useSelector } from 'react-redux'
import { selectShowImportFromUrl } from 'selectors/mainView'
import { MovableModalContainer } from 'components/MovableModal'
import ImportFromUrl from './ImportFromUrl'

export const ImportFromUrlContainer = () => {
  const isShowImportFromUrl = useSelector(selectShowImportFromUrl)
  return (
    <MovableModalContainer
      visible={isShowImportFromUrl}
      width={512}
      height={180}
      restorePosition={false}
      withBackground
    >
      <ImportFromUrl />
    </MovableModalContainer>
  )
}
