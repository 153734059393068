import { createSelector } from 'reselect'
import { PlayerType } from 'types/common'
import { PreviewState } from 'types/preview'

export const selectorSplitPlayers = createSelector([
  state => state.preview.splitPlayers,
], splitPlayers => splitPlayers as boolean)

export const selectorActivePreview = createSelector([
  state => state.preview.activePreview,
], activePreview => activePreview as PlayerType)

export const selectorFullScreen = createSelector([
  state => state.preview.fullScreen,
], fullScreen => fullScreen as boolean)

export const selectorPreviewDuration = createSelector([
  state => state.preview.duration,
], duration => duration as number)

export const selectorPreviewWidth = createSelector([
  state => state.preview.width,
], width => width as number)

export const selectorPreviewHeight = createSelector([
  state => state.preview.height,
], height => height as number)

export const selectorPreview = createSelector([
  state => state.preview,
], preview => preview as PreviewState)

export const selectorPreviewClipCreator = createSelector([
  state => state.preview.clipCreator,
], clipCreatorMarks => clipCreatorMarks as PreviewState['clipCreator'])

export const selectShowInOutPoints = createSelector([
  (state: RootState) => state.preview.showInOutPoints,
], showInOutPoints => showInOutPoints as boolean)

export const selectInOutPointsTimeLineAssetID = createSelector([
  (state: RootState) => state.preview.inOutPointsTimeLineAsset,
], inOutPointsTimeLineAsset => inOutPointsTimeLineAsset as (string | null))

export const selectInOutPointsMoving = createSelector([
  (state: RootState) => state.preview.inOutPointsMoving,
], inOutPointsMoving => inOutPointsMoving as boolean)

export const selectSourcePlayerProgress = createSelector([
  state => state.preview.playerProgress.source,
], sourcePlayerProgress => sourcePlayerProgress as number)

export const selectPreviewLoading = createSelector([
  state => state.preview.isLoading,
], isLoading => isLoading as boolean)

export const selectPreviewMode = createSelector([
  state => state.preview.mode,
], mode => mode as string)

export const selectCanvasPreviewPlayerBounds = createSelector([
  state => state.preview.canvasVideoPlayer.bounds,
], bounds => bounds as DOMRect)
