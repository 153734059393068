import cx from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@material-ui/core'
import * as Actions from 'actions'
import { useAction } from 'hooks'
import * as Selectors from 'selectors'

import Checkbox from 'components/AssetPanels/Voiceover/components/Checkbox'
import Volume from 'components/AssetPanels/Voiceover/components/Volume'
import MenuItem from 'components/base/MenuItem'
import Select from 'components/base/Select'
import InfoView from 'components/views/InfoView/InfoView'

import Scrollbars from 'components/base/Scrollbars'
import { TranslationContext } from 'contexts/TranslationContext'
import styles from './options.module.scss'

type Devices = Record<MediaDeviceInfo['kind'], Array<{
  id: string,
  label: MediaDeviceInfo['label']
}>>

function useDevicesList(granted: boolean) {
  const [ devices, setDevices ] = useState<Devices>({ audioinput: [],
    videoinput: [],
    audiooutput: [] })

  useEffect(() => {
    if (navigator.mediaDevices?.enumerateDevices) {
      if (!granted) return
      // List cameras and microphones.
      navigator.mediaDevices.enumerateDevices()
        .then(devices => {
          const payload: Devices = { audioinput: [], videoinput: [], audiooutput: [] }
          devices.forEach(device => {
              payload[device.kind]?.push({ label: device.label, id: device.deviceId })
          })
          setDevices(payload)
        })
        .catch(err => {
          console.error(`${err.name}: ${err.message}`)
        })
    }
  }, [ granted ])

  return [ devices.audioinput, devices.videoinput ]
}

function getCurrentDevice(audioDevices: Devices['audioinput']) {
  // TODO: cache in localstorage
  return audioDevices.length ? audioDevices[0].id : null
}

type Props = {
  onChange: (patch: Record<string, unknown>) => void,
  handleClose: () => void,
  stream: MediaStream | null,
  disable: boolean,
  constraints: MediaTrackConstraints,
  children: React.ReactNode | React.ReactNodeArray
}

function Options({ onChange, handleClose, stream, disable, constraints, children }: Props) {
  const [ deviceId, setDeviceId ] = useState<string | null>(null)
  const [ audioDevices ] = useDevicesList(stream !== null)

  const setCountdown = useAction(Actions.recording.setCountdown)
  const countdownState = useSelector(Selectors.recording.selectCountdown)

  const rewindSlider = useSelector(Selectors.recording.selectRewindSlider)
  const setRewindSlider = useAction(Actions.recording.setRewindSlider)

  const muted = useSelector(Selectors.recording.selectMuted)
  const setMuted = useAction(Actions.recording.setMuted)

  useEffect(() => {
    const device = getCurrentDevice(audioDevices)
    setDeviceId(device)
    onChange({ deviceId: device })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ audioDevices ])

  function handleChangeDevice(e: React.ChangeEvent<HTMLInputElement>) {
    onChange({ deviceId: e.target.value })
    setDeviceId(e.target.value)
  }

  function handleAutogainChange(value: boolean) {
    onChange({ autoGainControl: value })
  }

  function handleCountdownChange(e: React.ChangeEvent<HTMLInputElement>) {
    setCountdown({ type: 'audio', value: e.target.checked })
  }

  function handleRewindChange(value: boolean) {
    setRewindSlider({ type: 'audio', value })
  }

  function handleMutedChange(value: boolean) {
    setMuted({ type: 'audio', value })
  }

  const { t } = useContext(TranslationContext)

  return (
    <Scrollbars>
      <InfoView header={t('VOICE_OVER_TEXT_VOICE_NARRATION')} className={styles.infoView} onHide={handleClose}>
        <div className={cx(styles.options, { [styles.disabled]: disable })}>
          <div className={styles.selectContainer}>
            <div className={styles.label}>{t('VOICE_OVER_TEXT_MIC')}</div>
            <Select
              value={audioDevices.length !== 0 ? deviceId || '' : 'noAudio'}
              onChange={handleChangeDevice}
              className={styles.select}
              label={t('VOICE_OVER_LABEL_SELECT_MIC')}
              disabled={disable}
            >
              {audioDevices.length !== 0
                ? audioDevices.map(device => (<MenuItem key={device.id} value={device.id}>{device.label}</MenuItem>))
                : <MenuItem value="noAudio">{t('VOICE_OVER_MENU_ITEM_NO_AUDIO')}</MenuItem>}
            </Select>
          </div>
          <div className={styles.volume}>
            <Volume
              stream={stream}
              constraints={constraints}
              disable={disable}
              onAutoGainChange={handleAutogainChange}
            />
          </div>
          <Box marginBottom="12px">{t('VOICE_OVER_VOLUME_TEXT_OTHER_SETTINGS')}</Box>
          <div className={styles.checkbox}>
            <Checkbox
              checked={muted}
              onChange={handleMutedChange}
              disabled={disable}
              classes={{ root: styles.checkboxRoot }}
              emitValue
            />
            <div>{t('VOICE_OVER_TEXT_MUTE_TIMELINE')}</div>
          </div>
          <div className={styles.checkbox}>
            <Checkbox
              checked={rewindSlider}
              onChange={handleRewindChange}
              classes={{ root: styles.checkboxRoot }}
              disabled={disable}
              emitValue
            />
            <div>{t('VOICE_OVER_TEXT_REWIND_SLIDER_AT_START_OF_RECORD')}</div>
          </div>
          <div className={styles.checkbox}>
            <Checkbox
              checked={countdownState}
              classes={{ root: styles.checkboxRoot }}
              disabled={disable}
              onChange={handleCountdownChange}
            />
            <div>{t('VOICE_OVER_TEXT_COUNTDOWN')}</div>
          </div>
        </div>
        {children}
      </InfoView>
    </Scrollbars>
  )
}

export default Options
