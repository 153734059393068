import cx from 'classnames'
import React, { useContext, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { conditionalRefTimeToHHMMSS } from 'Util'
import * as Actions from 'actions'
import { useAction } from 'hooks'
import * as Selectors from 'selectors'

import Options from 'components/AssetPanels/Voiceover/components/Options'
import Retake from 'components/AssetPanels/Voiceover/components/Retake'
import useDisconnected from 'components/AssetPanels/Voiceover/hooks/useDisconnected'
import useRecording from 'components/AssetPanels/Voiceover/hooks/useRecording'
import Button from 'components/base/Button'
import { TranslationContext } from 'contexts/TranslationContext'
import { ReactComponent as RecIcon } from '~/assets/media/voice_record.svg'
import styles from './voiceover.module.scss'

type Props = {
  onClose: () => void
}

function Voiceover({ onClose }: Props) {
  const closeClicked = useRef(false)

  const sliderTime = useSelector((state: RootState) => state.timeline.sliderTime)
  const startTime = useRef(sliderTime)

  const recordingStatus = useSelector(Selectors.recording.selectRecordingStatus)
  const startRecording = useAction(Actions.recording.startRecording)
  const stopRecording = useAction(Actions.recording.stopRecording)
  const deleteRecording = useAction(Actions.recording.deleteRecording)

  const lastRecord = useSelector(Selectors.recording.selectLastMediaRecording)

  const { sessionId,
    connectStatus,
    constraints,
    stream,
    setConstraints,
    setConnectStatus,
    createConnection,
    closeConnection } = useRecording()

  const sessionIdRef = useRef(sessionId)
  sessionIdRef.current = sessionId

  const recordingStatusRef = useRef(recordingStatus)
  const connectStatusRef = useRef(connectStatus)


  const countdownTimerId = useRef<number | undefined>(undefined)

  const countdownOption = useSelector(Selectors.recording.selectCountdown)
  const showCountdown = useSelector(Selectors.recording.selectShowCountdown)
  const setShowCountdown = useAction(Actions.recording.setShowCountdown)

  const retakeRecording = useAction(Actions.recording.retakeRecording)

  const playerVolume = useSelector(Selectors.mainView.selectPlayerVolume)
  const prevPlayerVolume = useRef(playerVolume)

  const offlineRestoration = useSelector(Selectors.mainView.selectOfflineRestoration)

  useDisconnected({ createConnection, connectStatus, recordingStatus, closeConnection })

  useEffect(() => {
    prevPlayerVolume.current = playerVolume
    recordingStatusRef.current = recordingStatus
    connectStatusRef.current = connectStatus
  }, [ playerVolume, connectStatus, recordingStatus ])

  const disableOptions = recordingStatus === 'progress'
    || recordingStatus === 'saving' || showCountdown || offlineRestoration

  /**
   * Create new recording session right after recording or disconnect/fail
   */
  useEffect(() => {
    // disconnected and failed handled by useDisconnected
    if ((recordingStatus === 'stop' || recordingStatus === 'error')
      && connectStatusRef.current !== 'disconnected' && connectStatusRef.current !== 'failed') {
      createConnection()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ recordingStatus ])

  useEffect(() => () => {
    window.clearTimeout(countdownTimerId.current)
    resetCountdown()
    closeConnection()
    if (closeClicked.current) return
    (async function fn() {
      if (recordingStatusRef.current === 'progress') {
        await stopRecording()
      }
    }())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!showCountdown) {
      window.clearInterval(countdownTimerId.current)
    }
  }, [ showCountdown ])

  function handleClose() {
    closeClicked.current = true
    if (recordingStatus === 'progress') {
      handleStop()
    }
    onClose()
  }
  const { t } = useContext(TranslationContext)

  function handleStart() {
    startTime.current = sliderTime
    try {
      const fn = () => startRecording(sessionId)
      if (countdownOption) {
        setShowCountdown(true)
        countdownTimerId.current = window.setTimeout(fn, 3000)
      } else {
        fn()
      }
    } catch (e) {
      setConnectStatus('closed')
    }
  }

  function handleStop() {
    setConnectStatus('closed')
    stopRecording()
  }

  function handleDelete() {
    deleteRecording()
    setConnectStatus('closed')
  }

  function handleRetake() {
    if (!lastRecord) return
    try {
      startTime.current = lastRecord.startTime
      const fn = () => retakeRecording(sessionId)
      if (countdownOption) {
        setShowCountdown(true)
        countdownTimerId.current = window.setTimeout(fn, 3000)
      } else {
        fn()
      }
    } catch (e) {
      setConnectStatus('closed')
    }
  }

  const formatTime = conditionalRefTimeToHHMMSS(sliderTime - startTime.current, true)

  const controls = (() => {
    const startDisabled = connectStatus !== 'connected' || !constraints.deviceId || offlineRestoration
    const deleteDisabled = showCountdown
    switch (showCountdown || recordingStatus) {
      case 'stop':
      case 'error':
      case 'saving':
        return (
          <Button
            className={cx(styles.button, styles.start)}
            onClick={handleStart}
            classes={{
              startIcon: styles.startIcon,
            }}
            startIcon={!startDisabled ? <RecIcon className={cx(styles.icon)} /> : null}
            disabled={startDisabled}
            primary
            title={!startDisabled
              ? t('VOICE_OVER_BTN_START_RECORDING_TOOLTIP')
              : t('VOICE_OVER_BTN_CONNECTION_TOOLTIP')}
            data-lang-id={!startDisabled ? 'VOICE_OVER_BTN_START_RECORDING' : 'VOICE_OVER_BTN_CONNECTION'}
          >
            {!startDisabled ? t('VOICE_OVER_BTN_START_RECORDING') : t('VOICE_OVER_BTN_CONNECTION')}
          </Button>
        )

      case 'progress':
      default:
        return (
          <>
            <Button
              className={cx(styles.button, styles.delete)}
              onClick={handleDelete}
              startIcon={<div className={cx(styles.icon, styles.deleteIcon)} />}
              disabled={deleteDisabled}
              title={t('VOICE_OVER_BTN_DELETE_TOOLTIP')}
              data-lang-id="VOICE_OVER_BTN_DELETE"
            />
            {recordingStatus === 'progress' && <div className={styles.timer}>{formatTime}</div>}
            <Button
              className={cx(styles.button, styles.stop)}
              startIcon={<div className={cx(styles.icon, styles.stopIcon)} />}
              onClick={!showCountdown
                ? handleStop
                : resetCountdown}
              primary
              title={t('VOICE_OVER_BTN_STOP_TOOLTIP')}
              data-lang-id="VOICE_OVER_BTN_STOP"
            >
              {t('VOICE_OVER_BTN_STOP')}
            </Button>
          </>
        )
    }
  })()

  function resetCountdown() {
    setShowCountdown(false)
  }

  return (
    <>
      <div className={styles.container}>
        <Options
          onChange={setConstraints}
          constraints={constraints}
          handleClose={handleClose}
          stream={stream}
          disable={disableOptions}
        >
          <Retake connectStatus={connectStatus} retake={handleRetake} />
        </Options>
        <div className={styles.controls}>
          {controls}
        </div>
      </div>
    </>
  )
}

export default Voiceover
