import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { MovableContainerType } from 'types/modals/movable'

export const useMovableModal = ({ visible, width, height, restorePosition }:
  { visible: boolean, width: number, height: number, restorePosition: boolean }) => {
  const movableContainerRef = useRef<MovableContainerType>(null)
  const mouseCoords = useRef<Record<'x' | 'y', number>>({ x: 0, y: 0 })
  const [ isMouseDown, setIsMouseDown ] = useState<boolean>(false)

  const isAllowed = (e: MouseEvent) => {
    const elem = e.target as Element
    return !!Array.from(elem.classList).find(className => (
      className.includes('settings-head') || className.includes('asset-settings__content')
    ))
  }

  const {
    left,
    top,
    documentWidth,
    documentHeight,
  } = useMemo(() => {
    let left = 0
    let top = 0
    const documentWidth = window.document.documentElement.offsetWidth
    const documentHeight = window.document.documentElement.offsetHeight
    if (movableContainerRef.current) {
      const { left: x, top: y } = movableContainerRef.current.getBoundingClientRect()
      left = x
      top = y
    }
    return {
      left,
      top,
      documentWidth,
      documentHeight,
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isMouseDown ])

  const moveModal = useCallback((e: MouseEvent) => {
    if (isMouseDown && movableContainerRef.current) {
      e.stopPropagation()
      window.getSelection()?.removeAllRanges()

      const minTransformX = -left - (width / 2)
      const minTransformY = -top - (height / 2)
      const maxTransformX = documentWidth - left - width - (width / 2)
      const maxTransformY = documentHeight - top - height - (height / 2)

      let x = e.clientX - mouseCoords.current.x - (width / 2)
      let y = e.clientY - mouseCoords.current.y - (height / 2)

      if (x <= minTransformX) x = minTransformX
      if (y <= minTransformY) y = minTransformY
      if (x >= maxTransformX) x = maxTransformX
      if (y >= maxTransformY) y = maxTransformY

      movableContainerRef.current.style.transform = `translate(${x}px, ${y}px)`
      movableContainerRef.current.style.left = `${left + (width / 2)}px`
      movableContainerRef.current.style.top = `${top + (height / 2)}px`
    }
  }, [
    left,
    top,
    documentWidth,
    documentHeight,
    width,
    height,
    isMouseDown,
    mouseCoords,
  ])

  const onMouseDownNUp = useCallback((e: MouseEvent) => {
    if (!isAllowed(e)) return
    setIsMouseDown(prev => {
      if (!prev) {
        if (!mouseCoords.current.x && !mouseCoords.current.y) {
          mouseCoords.current.x = e.clientX
          mouseCoords.current.y = e.clientY
        }
      } else {
        mouseCoords.current = { x: 0, y: 0 }
      }

      return !prev
    })
  }, [])

  const onMouseUp = (e: MouseEvent) => {
    if (!isAllowed(e)) return
    setIsMouseDown(false)
    mouseCoords.current = { x: 0, y: 0 }
    if (movableContainerRef.current) {
      const { transform, left, top } = movableContainerRef.current.style
      sessionStorage.setItem('clip_settings', JSON.stringify({
        transform,
        left,
        top,
      }))
    }
  }

  useEffect(() => {
    const container = movableContainerRef.current
    container?.addEventListener('mousedown', onMouseDownNUp)
    container?.addEventListener('mouseup', onMouseDownNUp)
    window?.addEventListener('mousemove', moveModal)
    window?.addEventListener('mouseup', onMouseUp)

    return () => {
      container?.removeEventListener('mousedown', onMouseDownNUp)
      container?.removeEventListener('mouseup', onMouseDownNUp)
      window?.removeEventListener('mousemove', moveModal)
      window?.removeEventListener('mouseup', onMouseUp)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ onMouseDownNUp, moveModal, visible ])

  useEffect(() => {
    const styles = window.sessionStorage.getItem('clip_settings')
    if (visible && styles && movableContainerRef.current && restorePosition) {
      const { transform, left, top } = JSON.parse(styles)
      movableContainerRef.current.style.transform = transform
      movableContainerRef.current.style.left = left
      movableContainerRef.current.style.top = top
    }
  }, [ visible, restorePosition ])
  return {
    movableContainerRef,
    isMouseDown,
  }
}
