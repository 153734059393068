import * as classNames from 'classnames'
import { clamp } from 'lodash'
import React, { useRef } from 'react'
import { useSelector } from 'react-redux'

import { useAction } from 'hooks'

import { MIN_TIMELINE_ITEM_DURATION_PX } from 'constant'
import { selectTimelineScale } from 'selectors'
import { pixel2Time, time2Pixel } from '~/Util'
import { isImage, isText } from '~/Util/assets'
import * as Actions from '~/actions'

import { useMouseItemEvents } from './useMouseItemEvents'
import { PREVIEW_MODE } from '~/config/constants/preview'
import { PLAYER_TYPE } from '~/enums'
import { usePreviewSwitcher } from '~/hooks/preview/usePreviewSwitcher'

export const Expander = ({ asset, isDragging, onStartExpanding,
  onEndExpanding, left = null, right = null }) => (
    <>
      {!left && (
      <ExpanderLine
        onStartExpanding={onStartExpanding}
        onEndExpanding={onEndExpanding}
        asset={asset}
        rightTr={right}
        isDragging={isDragging}
      />
      )}
      {!right && (
      <ExpanderLine
        onStartExpanding={onStartExpanding}
        onEndExpanding={onEndExpanding}
        right
        leftTr={left}
        asset={asset}
        isDragging={isDragging}
      />
      )}
    </>
)

const ExpanderLine = ({ right, asset, isDragging, leftTr, rightTr,
  onStartExpanding, onEndExpanding }) => {
  const scale = useSelector(selectTimelineScale)
  const updateAsset = useAction(Actions.layer.updateAssetInPreview, asset.id)
  const currentPatch = useRef(null)
  const {
    switchPreviewMediaToTimeline,
    isSplittedPreview,
    activePreviewMode,
    activePreview,
  } = usePreviewSwitcher()

  const minDuration = pixel2Time(MIN_TIMELINE_ITEM_DURATION_PX, scale)
    + (right ? leftTr?.duration || 0 : rightTr?.duration || 0)

  const stopUpdating = useAction(Actions.layer.stopAssetPreviewUpdating)
  const updatePreviewMarkers = useAction(Actions.preview.updatePreviewMarkers)

  const moveExpandingMedia = ({ timePosition }) => {
    const { duration, endTime, startTime, mediaFileDuration, mediaStart } = asset

    const patch = {
      ...(!right ? {
        startTime: Math.max(Math.min(timePosition, endTime - minDuration),
          startTime - mediaStart),
        mediaStart: clamp(mediaStart - (startTime - Math.min(timePosition, endTime - minDuration)),
          0, mediaFileDuration - minDuration),
        endTime, // see endTime setter
      } : {
        duration: Math.max(duration + Math.min((timePosition - endTime),
          mediaFileDuration - (mediaStart + duration)), minDuration),
      }),
    }
    updateAsset(patch)
    currentPatch.current = patch
  }

  const moveExpandingStatic = ({ timePosition }) => {
    const { duration, endTime, startTime, mediaFileDuration, mediaStart } = asset
    const newDuration = Math.max(duration + (timePosition - endTime), minDuration)
    const newMediaFileDuration = (newDuration + mediaStart) > mediaFileDuration
      ? (newDuration + mediaStart) : mediaFileDuration
    const patch = {
      ...(!right ? {
        startTime: Math.max(Math.min(timePosition, endTime - minDuration),
          startTime - mediaStart),
        mediaStart: clamp(mediaStart - (startTime - Math.min(timePosition, endTime - minDuration)),
          0, mediaFileDuration - minDuration),
        endTime,
      } : {
        duration: newDuration,
        mediaFileDuration: newMediaFileDuration,
        originDuration: newMediaFileDuration,
      }),
    }

    updateAsset(patch)
    currentPatch.current = patch
  }

  const handleMoveExpanding = (isText(asset.type) || isImage(asset))
    ? moveExpandingStatic
    : moveExpandingMedia

  const onMouseUp = () => {
    document.body.style.cursor = 'default'

    stopUpdating({ asset,
      initProps: { startTime: asset.startTime,
        duration: asset.duration,
        mediaFileDuration: asset.mediaFileDuration,
        mediaStart: asset.mediaStart } })
    onEndExpanding()
    updatePreviewMarkers(asset.id)
    currentPatch.current = null
  }

  const onStart = () => {
    document.body.style.cursor = 'ew-resize'
    if (
      !isSplittedPreview
      && (activePreviewMode === PREVIEW_MODE.AUTO)
      && (activePreview !== PLAYER_TYPE.TIMELINE)
    ) {
      switchPreviewMediaToTimeline()
    }
    onStartExpanding()
  }

  const { onMouseDown } = useMouseItemEvents({
    onMouseUp,
    onMouseDown: onStart,
    onMouseMove: handleMoveExpanding,
  })

  const width = time2Pixel(
    asset.duration - (right ? leftTr?.duration || 0 : rightTr?.duration || 0), scale
  )
  const hide = width < 44

  return (
    <div
      className={
        classNames('layer-item--image__expander',
          { 'layer-item--image__expander--right': right,
            'layer-item--image__expander--selected': asset.selected,
            'layer-item--image__expander--enable': isDragging || asset.selected,
            'layer-item--image__expander--hide': hide })
      }
      onMouseDown={onMouseDown}
    />
  )
}
