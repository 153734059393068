import produce from 'immer'
import * as ActionTypes from 'actions/ActionTypes'

/**
 * @typedef {object} InterfaceState
 * @property {object} splitSize
 * @property {boolean} resizing
 * @property {string | null} resizer
 */

/**
 * @type {InterfaceState}
 */
const initialState = {
  splitSize: {
    media: 0,
  },
  resizing: false,
  resizer: null,
}

const userInterface = (state, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_SPLIT_SIZE: {
      const { value, type } = action.payload
      state.splitSize[type] = value
    }
      break
    case ActionTypes.SET_RESIZING: {
      const { resizing, resizer } = action.payload
      if (resizing) {
        state.resizing = resizing
        state.resizer = resizer
      } else {
        state.resizing = false
        state.resizer = null
      }
    }
      break
    default:
      break
  }
}

export default produce(userInterface, initialState)
