import * as ActionTypes from 'actions/ActionTypes'
import { PREVIEW_MODE } from 'config/constants/preview'
import { PLAYER_TYPE } from 'enums'
import produce from 'immer'

const initialState = {
  scale: {
    x: 1,
    y: 1,
  },
  splitPlayers: __APP_PROFILE__ === 'itochu',
  mode: PREVIEW_MODE.AUTO,
  activePreview: PLAYER_TYPE.TIMELINE,
  inOutPointsTimeLineAsset: null,
  showInOutPoints: false,
  inOutPointsMoving: false,
  playerProgress: { source: 0 },
  canvasVideoPlayer: { bounds: {} },
  isLoading: false,
  fullScreen: null,
  width: 0,
  height: 0,
  progress: 0,
  duration: 0,
  clipCreator: {
    [PLAYER_TYPE.TIMELINE]: {
      markIn: null,
      markOut: null,
    },
    [PLAYER_TYPE.MEDIA]: {
      markIn: null,
      markOut: null,
    },
  },
}

const preview = (state, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case ActionTypes.SET_PREVIEW_PARAMS: {
      const { scale, boundingRect } = action.payload
      state.scale = scale
      state.boundingRect = boundingRect
    }
      break
    case ActionTypes.SPLIT_PREVIEW:
      state.splitPlayers = !state.splitPlayers
      break
    case ActionTypes.SET_ACTIVE_PREVIEW: {
      const { activePreview } = action.payload
      state.activePreview = activePreview
    }
      break
    case ActionTypes.SET_FULLSCREEN: {
      const { fullScreen } = action.payload
      state.fullScreen = fullScreen ?? !state.fullScreen
    }
      break
    case ActionTypes.SET_RESIZABLE_PARAMS: {
      const { width, height } = action.payload
      state.width = width
      state.height = height
    }
      break
    case ActionTypes.ON_CHANGE_DURATION: {
      const { duration } = action.payload
      state.duration = duration
    }
      break
    case ActionTypes.SET_PREVIEW_MARKER: {
      const { preview, point, markType } = action.payload
      state.clipCreator[preview][markType] = point
    }
      break
    case ActionTypes.SET_PREVIEW_MARKERS: {
      const { data, preview } = action.payload
      if (preview) {
        state.clipCreator[preview] = data
      } else {
        state.clipCreator = data
      }
    }
      break
    case ActionTypes.RESET_MARK: {
      const { preview, markType } = action.payload
      if (markType) {
        state.clipCreator[preview][markType] = null
      } else {
        state.clipCreator[preview] = { markIn: null, markOut: null }
      }
    }
      break
    case ActionTypes.SET_IN_OUT_POINTS_TIMELINE_ASSET: {
      const { assetId } = action.payload
      if (state.splitPlayers || [ PREVIEW_MODE.AUTO, PREVIEW_MODE.SOURCE ].includes(state.mode)) {
        state.inOutPointsTimeLineAsset = assetId
      }
    }
      break
    case ActionTypes.SET_SHOW_IN_OUT_POINTS: {
      const { show } = action.payload
      state.showInOutPoints = show
    }
      break
    case ActionTypes.SET_IN_OUT_POINTS_MOVING: {
      const { isMoving } = action.payload
      state.inOutPointsMoving = isMoving
    }
      break
    case ActionTypes.SET_SOURCE_PLAYER_PROGRESS: {
      const { progress } = action.payload
      state.playerProgress.source = progress
    }
      break
    case ActionTypes.SET_PREVIEW_LOADING: {
      const { isLoading } = action.payload
      state.isLoading = isLoading
    }
      break
    case ActionTypes.SET_PREVIEW_MODE: {
      const { mode } = action.payload
      state.mode = mode
    }
      break
    case ActionTypes.SET_CANVAS_PLAYER_BOX_BOUNDS: {
      const { bounds } = action.payload
      state.canvasVideoPlayer.bounds = bounds
    }
      break
  }
}

export default produce(preview, initialState)
