import { useEffect } from 'react'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { DragSourceMonitor, useDrag } from 'react-dnd'
import { noop } from 'lodash'

import * as Actions from 'actions'
import * as Assets from 'models/Asset'
import { DRAGNDROP_TYPE, MEDIA_ERROR_TYPE } from 'enums'
import { useAction } from 'hooks/utils'
import { startDragAsset as startDragAssetAction } from 'actions/timeline'

type Options = {
  draggable: boolean,
  onDenied(): void,
  onDragStarted(): void,
}

type DRAGNDROP_TYPE = typeof DRAGNDROP_TYPE[keyof typeof DRAGNDROP_TYPE]

type DragObject = {
  type: DRAGNDROP_TYPE,
  id: string,
  asset: Assets.Asset,
}
type CollectedProps = {
  isDragging: boolean,
}
type DropResult = Record<string, unknown>

function useDragPreviewClipCreator(asset: Assets.Asset, options: Options) {
  const setDndDropTarget = useAction(Actions.mainView.setDndDropTarget)
  const startDragAsset = useAction(startDragAssetAction, asset?.id)
  const { onDenied = noop, onDragStarted = noop, draggable = true } = options

  const [ collected, drag, dragPreview ] = useDrag<DragObject, DropResult, CollectedProps>({
    item: {
      id: asset?.id,
      type: DRAGNDROP_TYPE.PREVIEW_MEDIA_ITEM,
      asset,
    },
    begin() {
      startDragAsset()
      onDragStarted()
      setDndDropTarget('root')
    },
    canDrag() {
      if (!draggable) {
        return false
      }
      if (asset.uploading
        || (asset.error && asset.error.type !== MEDIA_ERROR_TYPE.BUILD_THUMBNAILS)) {
        onDenied()
        return false
      }
      return true
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true })
  }, [ dragPreview ])

  return {
    collected,
    setDraggable: drag,
  }
}

export default useDragPreviewClipCreator
