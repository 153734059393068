import { patchAsset } from 'actions/layer'
import { setActivePreview, setInOutPointsTimelineAsset, setShowInOutPoints, resetMarkers, setSourcePlayerProgress, setPreviewMarkers } from 'actions/preview'
import { MARK_TYPE, PREVIEW_MODE } from 'config/constants/preview'
import { PLAYER_TYPE } from 'enums'
import { isEmpty } from 'lodash'
import { all, takeEvery, put, select, takeLatest } from 'redux-saga/effects'
import { selectAssetById, selectSliderTime } from 'selectors'
import { secondsToTimelineTime, timelineTimeToSeconds } from '~/Util'
import * as ActionTypes from '~/actions/ActionTypes'

function* resetInOutPointsAsset({ type, payload }) {
  if ([ ActionTypes.DELETE_ASSETS, ActionTypes.RIPPLE_DELETE_ASSETS ].includes(type)) {
    const { assets } = payload
    if (!isEmpty(assets)) {
      const ioPointAssetID = yield select(state => state.preview.inOutPointsTimeLineAsset)
      const removedIOPointsAsset = assets.find(asset => asset.id === ioPointAssetID)
      if (removedIOPointsAsset) {
        yield put(setInOutPointsTimelineAsset(null))
        yield put(setShowInOutPoints(false))
        yield resetMediaPreviewMarkers()
        yield put(setSourcePlayerProgress(0))
      }
    }
  } else {
    // For ActionTypes.SET_PLAYBACK_MEDIA
    yield put(setInOutPointsTimelineAsset(null))
    yield resetMediaPreviewMarkers()
    const { resetPreviewProgress, asset } = payload ?? {}
    if (resetPreviewProgress) {
      yield put(setSourcePlayerProgress(0))
    }

    let ioPoints = null
    if (asset?.id) {
      ioPoints = sessionStorage.getItem(asset.id)
      if (ioPoints) {
        const data = JSON.parse(ioPoints)
        yield put(setPreviewMarkers({
          [MARK_TYPE.IN]: data.markIn,
          [MARK_TYPE.OUT]: data.markOut,
        }, { showIOPoints: true, preview: PLAYER_TYPE.MEDIA }))
      }
    } else {
      yield put(setShowInOutPoints(!!ioPoints))
    }
  }
}

function* switchActivePreview({ type, payload }) {
  const isSplittedPreview = yield select(state => state.preview.splitPlayers)
  const activePreview = yield select(state => state.preview.activePreview)
  const activePreviewMode = yield select(state => state.preview.mode)
  const inOutPointsTimeLineAssetID = yield select(state => state.preview.inOutPointsTimeLineAsset)
  switch (type) {
    case ActionTypes.SET_IS_MOVING_SLIDER:
    case ActionTypes.TIMELINE_REWINDED:
    case ActionTypes.ASSET_ADDED_TO_TIMELINE:
      if (activePreview !== PLAYER_TYPE.TIMELINE) {
        yield put(setActivePreview(PLAYER_TYPE.TIMELINE))
      }
      break
    case ActionTypes.ASSET_SELECTED: {
      const { id } = payload ?? {}
      if ((activePreview !== PLAYER_TYPE.TIMELINE) && (id !== inOutPointsTimeLineAssetID)) {
        yield put(setActivePreview(PLAYER_TYPE.TIMELINE))
      }
      break
    }
    case ActionTypes.SET_PLAYBACK_MEDIA:
      if (activePreview !== PLAYER_TYPE.MEDIA) {
        if (isSplittedPreview || (activePreviewMode === PREVIEW_MODE.AUTO)) {
          yield put(setActivePreview(PLAYER_TYPE.MEDIA))
        }
      }
      break
    default:
      break
  }
}

function* resetMediaPreviewMarkers() {
  yield put(resetMarkers({ preview: PLAYER_TYPE.MEDIA }))
}

function* patchAssetWithIOPoints() {
  const progress = yield select(state => state.preview.playerProgress.source)
  const inOutPointsTimeLineAssetId = yield select(state => state.preview.inOutPointsTimeLineAsset)
  const asset = yield select(state => selectAssetById(state, inOutPointsTimeLineAssetId))
  if (asset) {
    yield put(patchAsset(asset, {
      playerProgress: secondsToTimelineTime(progress),
    }, { historyAction: false }))
  }
}

function* updatePreviewMarkers({ payload }) {
  const { assetId } = payload
  const inOutPointsTimeLineAssetId = yield select(state => state.preview.inOutPointsTimeLineAsset)
  const sliderTime = yield select(selectSliderTime)
  if (assetId === inOutPointsTimeLineAssetId) {
    const asset = yield select(state => selectAssetById(state, assetId))
    const { mediaStart, duration, mediaFileDuration } = asset ?? {}

    yield put(setPreviewMarkers({
      [MARK_TYPE.IN]: !mediaStart ? 0 : timelineTimeToSeconds(mediaStart),
      [MARK_TYPE.OUT]: duration === mediaFileDuration
        ? timelineTimeToSeconds(duration)
        : timelineTimeToSeconds(duration + (mediaStart ?? 0)),
    }, { showIOPoints: true, preview: PLAYER_TYPE.MEDIA, updateAsset: false }))

    // Set media player progress depending on slidertime position (for doubleClick inOutPointsTimeLineAsset)
    if (asset.startTime <= sliderTime && asset.endTime >= sliderTime) {
      const playerProgress = sliderTime - asset.startTime + asset.mediaStart
      yield put(setSourcePlayerProgress(timelineTimeToSeconds(playerProgress)))
    }
  }
}

function* updatePreview() {
  const inOutPointsTimeLineAssetId = yield select(state => state.preview.inOutPointsTimeLineAsset)
  const asset = yield select(state => selectAssetById(state, inOutPointsTimeLineAssetId))
  if (asset) {
    const payload = { assetId: asset.id }
    yield updatePreviewMarkers({ payload })
  }
}

function* watchAll() {
  yield all([
    takeEvery([
      ActionTypes.SET_PLAYBACK_MEDIA,
      ActionTypes.ASSET_SELECTED,
      ActionTypes.ASSET_ADDED_TO_TIMELINE,
      ActionTypes.SET_IS_MOVING_SLIDER,
      ActionTypes.TIMELINE_REWINDED,
    ], switchActivePreview),
    takeEvery([
      ActionTypes.RESET_MAIN_VIEW,
    ], resetMediaPreviewMarkers),
    takeEvery([
      ActionTypes.SET_PLAYBACK_MEDIA,
      ActionTypes.DELETE_ASSETS,
      ActionTypes.RIPPLE_DELETE_ASSETS,
      ActionTypes.RESET_MAIN_VIEW,
    ], resetInOutPointsAsset),
    takeLatest([
      ActionTypes.SET_SOURCE_PLAYER_PROGRESS,
    ], patchAssetWithIOPoints),
    takeEvery(ActionTypes.UPDATE_PREVIEW_MARKERS, updatePreviewMarkers),
    takeLatest([
      ActionTypes.RIPPLE_TRIM,
      ActionTypes.UNDO_RIPPLE_TRIM,
      ActionTypes.UNDO_SPLITTING_ASSETS,
      ActionTypes.SPLIT,
    ], updatePreview),
  ])
}

export default watchAll
