import * as Assets from 'models/Asset'
import { useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAssets, getLayerAssets, selectAssetById } from 'selectors'
import {
  selectInOutPointsTimeLineAssetID,
  selectorActivePreview,
  selectShowInOutPoints
} from 'selectors/preview'
import { activeHotkeyProfileSelector } from 'selectors/user-profile-selector'
import { ClipCreatorMarkType } from 'types/preview'
import { MARK_TYPE } from 'config/constants/preview'
import { selectMediaServerSeekProcessing } from 'selectors/playback'
import { INITIAL_MOUSE_MOVEMENT_DATA } from './constant'
import { MouseMovementDataType } from './types'

type UseInitialData = {
  currentFps: number,
  asset: Assets.MediaAsset,
}

export const useInitialData = ({ currentFps, asset }: UseInitialData) => {
  const dispatch = useDispatch()
  const mouseMovementData = useRef<MouseMovementDataType>({ ...INITIAL_MOUSE_MOVEMENT_DATA })
  const timeout = useRef<NodeJS.Timeout | null>(null)
  const ioPointsBarRef = useRef<HTMLDivElement | undefined>(undefined)

  // clip segment dragging
  const [ isDragging, setDragging ] = useState<boolean>(false)

  const activeHotkeyProfile = useSelector(activeHotkeyProfileSelector)
  const activePreview = useSelector(selectorActivePreview)
  const showInOutPoints = useSelector(selectShowInOutPoints)
  const isMediaServerProcessing = useSelector(selectMediaServerSeekProcessing)
  const assets = useSelector(getAssets)
  const inOutPointsTimeLineAssetId = useSelector(selectInOutPointsTimeLineAssetID)
  const inOutPointsTimeLineAsset = useSelector(
    state => selectAssetById(state, inOutPointsTimeLineAssetId)
  ) as Assets.MediaAsset
  const layerAssets = useSelector(
    state => getLayerAssets(state, inOutPointsTimeLineAsset?.layerId)
  ).filter((asset: Assets.MediaAsset) => asset.id !== inOutPointsTimeLineAssetId)
    .sort((a: Assets.MediaAsset, b: Assets.MediaAsset) => a.startTime - b.startTime)

  const isStaticAsset = asset instanceof Assets.ImageAsset || asset instanceof Assets.TextAsset

  const minClipDuration = 1 / currentFps

  const getTimePointToPercent = useCallback(({ markType, duration, time }: {
    markType: ClipCreatorMarkType, duration: number, time: number
  }) => (
    markType === MARK_TYPE.IN
      ? `${((time / duration) * 100)}%`
      : `${(100 - ((time / duration) * 100))}%`
  ), [])

  const isResize = Boolean(mouseMovementData.current.markType)

  return {
    dispatch,
    mouseMovementData,
    timeout,
    activeHotkeyProfile,
    activePreview,
    showInOutPoints,
    inOutPointsTimeLineAsset,
    minClipDuration,
    getTimePointToPercent,
    layerAssets,
    inOutPointsTimeLineAssetId,
    setDragging,
    isDragging,
    ioPointsBarRef,
    isResize,
    isMediaServerProcessing,
    assets,
    isStaticAsset,
  }
}
