import { createSelector } from 'reselect'

export const selectMediaRecordingType = (state: RootState) => state.mainView.showFeatures.mediaRecordingSettings

export const selectPlayerVolume = (state: RootState) => state.mainView.playerVolume as number

export const selectOfflineRestoration = (state: RootState) => state.mainView.offlineRestoration
export const selectMenuItem = createSelector([
  (state: RootState) => state.mainView.menuItem,
], menuItem => menuItem)

export const selectShowSaveSettings = createSelector([
  (state: RootState) => state.mainView.showSaveSettings,
], showSaveSettings => showSaveSettings)

export const selectShowCustomizeView = createSelector([
  (state: RootState) => state.mainView.showCustomizeView,
], showCustomizeView => showCustomizeView)

export const selectShowImportFromUrl = createSelector([
  (state: RootState) => state.mainView.showImportFromUrl,
], showImportFromUrl => showImportFromUrl)
