import React, { FC, MouseEvent, useContext } from 'react'
import Menu from '@material-ui/core/Menu'
import { Box } from '@material-ui/core'
import MenuItem from 'components/base/MenuItem'
import { CLIP_CREATOR_CONTEXT_MENU, MARK_TYPE } from 'config/constants/preview'
import { ClipCreatorMarkType } from 'types/preview'
import { useSelector } from 'react-redux'
import { selectInOutPointsTimeLineAssetID } from 'selectors/preview'
import { TranslationContext } from 'contexts/TranslationContext'

interface IProps {
  anchorEl: HTMLDivElement | null
  onMarkOut(e: MouseEvent): void
  onMarkIn(e: MouseEvent): void
  onClearInOutPoints(e: MouseEvent): void
  onCloseMenu(e: MouseEvent): void
  anchorPosition: {top: number, left: number},
  clipMarkers: Record<ClipCreatorMarkType, null | number>,
}

export const ClipCreatorContextMenu: FC<IProps> = ({
  onCloseMenu,
  anchorEl,
  anchorPosition,
  clipMarkers,
  ...rest
}) => {
  const { t } = useContext(TranslationContext)
  const inOutPointsTimeLineAsset = useSelector(selectInOutPointsTimeLineAssetID)
  return (
    <Menu
      keepMounted
      anchorEl={anchorEl}
      anchorPosition={anchorPosition}
      anchorReference="anchorPosition"
      classes={{ paper: 'clip-creator__dropdown-menu' }}
      open={!!anchorEl}
      onClose={onCloseMenu}
    >
      {(Object.entries(CLIP_CREATOR_CONTEXT_MENU)as [ClipCreatorMarkType, {
        TITLE: string,
        HOTKEY: string,
        CALLBACK_NAME: 'onMarkOut' | 'onMarkIn' | 'onClearInOutPoints',
      }][]).map(([ marker, menuItem ]) => (
        <div className="clip-creator__dropdown-menu-item dropdown-menu-item" key={marker}>
          <MenuItem
            className="dropdown-menu-item__body"
            disabled={(marker === MARK_TYPE.CLEAR_IN_OUT)
              && ((!clipMarkers.markIn
              && !clipMarkers.markOut)
              || Boolean(inOutPointsTimeLineAsset))}
            onClick={rest[menuItem.CALLBACK_NAME]}
          >
            <Box width="100%" display="flex" justifyContent="space-between">
              <span>{t(menuItem.TITLE)}</span>
              <span className="dropdown-menu-item__hotkey">{menuItem.HOTKEY}</span>
            </Box>
          </MenuItem>
        </div>
      ))}
    </Menu>
  )
}
